
export default {
  data() {
    return {
      sectionID: ''
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  created() {
    this.sectionID = this.$vnode.tag
  },
  mounted() {
    this.sectionID = this.$vnode.tag
  },
  methods: {
    srcset(img) {
      if (!img || img.trim().length == 0) return null

      const img2x = img.replace(/.(jpg|png|jpeg)$/, function (x) {
        return '@2x' + x
      })
      return img + ' 1x, ' + img2x + ' 2x'
    }
  }
}
